import { render, staticRenderFns } from "./notice.vue?vue&type=template&id=66834a81&scoped=true&"
import script from "./notice.vue?vue&type=script&lang=js&"
export * from "./notice.vue?vue&type=script&lang=js&"
import style0 from "./notice.vue?vue&type=style&index=0&id=66834a81&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66834a81",
  null
  
)

export default component.exports